import { useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/caregiverleft1.png";
import "./Bookcaregiver.css";
import { useFormik } from "formik";
import { useState } from "react";
import { completeprofile1 } from "../../service/Mainservice";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.birthdate) {
    errors.birthdate = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.contact) {
    errors.contact = "Required";
  }
  if (!values.ename) {
    errors.ename = "Required";
  } else if (values.ename.length > 100) {
    errors.ename = "Must be 100 characters or less";
  }
  if (!values.econtact) {
    errors.econtact = "Required";
  }

  return errors;
};

const Bookcaregiver = () => {
  const [load, setload] = useState(false);

  const navigate = useNavigate();

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);
    try {
      const response = await completeprofile1(values);
      navigate("/Billing&carddetail");
      localStorage.setItem("screen", "2");

      setload(false);
      resetForm();
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      birthdate: "",
      email: "",
      contact: "",
      ename: "",
      econtact: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });
  return (
    <>
      <Header title="Complete Profile" headerbg="caregiverbg" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <h2>Client Details</h2>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Client FullName</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="Bookcaregiver_input"
                  name="name"
                  type="text"
                  placeholder="Client Name"
                />
                {formik.errors.name ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Date of Birth</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.birthdate}
                  className="Bookcaregiver_input"
                  name="birthdate"
                  type="date"
                  placeholder="Date of Birth"
                  max={new Date().toISOString().split("T")[0]}
                />
                {formik.errors.birthdate ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.birthdate}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Email</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  type="text"
                  placeholder="Email"
                  className="Bookcaregiver_input"
                />
                {formik.errors.email ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="contact">Contact Number</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.contact}
                  name="contact"
                  type="number"
                  placeholder="Contact number"
                  className="Bookcaregiver_input"
                />
                {formik.errors.contact ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.contact}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Emergency Contact Name</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.ename}
                  name="ename"
                  type="text"
                  placeholder="Emergency Contact Name"
                  className="Bookcaregiver_input"
                />
                {formik.errors.ename ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.ename}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Emergency Contact Number</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.econtact}
                  name="econtact"
                  type="number"
                  placeholder="Emergency Contact Number"
                  className="Bookcaregiver_input"
                />
                {formik.errors.econtact ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.econtact}
                  </div>
                ) : null}
              </div>
            </div>
            <button className="Bookcaregiver_button" type="submit">
              {load ? "Loading....." : "Next"}
            </button>
          </form>
        </div>
        <img
          src={caregiverimg}
          alt="caregiver"
          className="Bookcaregiverimg"
          loading="lazy"
        />
      </div>
    </>
  );
};
export default Bookcaregiver;
