import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { resetpassword } from "../../service/Authservice";
import { Alert, Snackbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import loginimg from "../../assets/Forget.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: "690px" },
  bgcolor: "var(--white)",
  borderRadius: { xs: "0", md: "10px" },
  border: "none",
  height: { xs: "100%", md: "390px" },
  display: "flex",
};

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Must be at least 6 characters";
  } else if (values.password.length > 20) {
    errors.password = "Must be 20 characters or less";
  }
  if (!values.confpassword) {
    errors.confpassword = "Required";
  } else if (values.confpassword !== values.password) {
    errors.confpassword = "Password and Confirm Password must be same.";
  }
  return errors;
};

const ResetPassword = () => {
  const [error, seterror] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [load, setload] = useState();

  const unshowref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;

  useEffect(() => {
    if (!token) {
      navigate("/ForgetPassword");
    }

    const handleClickOutside = (event) => {
      if (unshowref.current && !unshowref.current.contains(event.target)) {
        navigate("/");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const closealert2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);

    try {
      const response = await resetpassword(values.password, token);
      setload(false);
      setOpen2(true);
      setsuccess(response.data.message);
      resetForm();
      navigate("/Login");
    } catch (error) {
      setload(false);
      setOpen(true);
      seterror(error.message);
    }
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      confpassword: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <div className="Auth_BG">
        <div className="Auth_main" ref={unshowref}>
          <img
            src={loginimg}
            alt="register"
            className="Auth_img"
            loading="lazy"
          />
          <div className="Auth_details">
            <h1 className="auth_title">Reset Password</h1>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5_432)">
                    <path
                      d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_432">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name="password"
                  type="text"
                  placeholder="Password"
                />
              </div>
              {formik.errors.password ? (
                <div className="validation_error">{formik.errors.password}</div>
              ) : null}
              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5_432)">
                    <path
                      d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_432">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.confpassword}
                  name="confpassword"
                  type="text"
                  placeholder="Confirm Password"
                />
              </div>
              {formik.errors.confpassword ? (
                <div className="validation_error">
                  {formik.errors.confpassword}
                </div>
              ) : null}
              <button
                type="submit"
                className="auth_submitbutton"
                disabled={load}
              >
                {load ? "Loading..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={open2}
        autoHideDuration={3000}
        onClose={closealert2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ResetPassword;
