import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/becameleft4.png";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { becamecaregiver4 } from "../../service/Mainservice";
import KYCPopup from "./KYC";
import Loader from "../../Loader/Loader";

const DailyAvailability = () => {
  const [kyc, setkyc] = useState();
  const [load, setload] = useState(false);
  const [kycload, setkycload] = useState(false);
  const [error, seterror] = useState();

  const [weekAvailability, setWeekAvailability] = useState({
    MON: { start: "", end: "", fullday: "" },
    TUE: { start: "", end: "", fullday: "" },
    WED: { start: "", end: "", fullday: "" },
    THU: { start: "", end: "", fullday: "" },
    FRI: { start: "", end: "", fullday: "" },
    SAT: { start: "", end: "", fullday: "" },
    SUN: { start: "", end: "", fullday: "" },
  });
  const [selectedDay, setSelectedDay] = useState("MON");

  const navigate = useNavigate();
  const isValid = Object.values(weekAvailability).some(
    (day) => day.start && day.end && day.fullday
  );

  useEffect(() => {
    isValid && seterror("");
  }, [weekAvailability]);

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    setWeekAvailability((prevAvailability) => ({
      ...prevAvailability,
      [selectedDay]: {
        ...prevAvailability[selectedDay],
        [name]: value,
      },
    }));
  };
  const handleFullDayChange = (e) => {
    setWeekAvailability((prevAvailability) => ({
      ...prevAvailability,
      [selectedDay]: {
        ...prevAvailability[selectedDay],
        fullday: e,
      },
    }));
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };
  console.log(weekAvailability);
  const handlesubmit = async () => {
    if (!isValid) {
      seterror("Please set availability for at least one day.");
      return;
    } else {
      setload(true);
      try {
        const response = await becamecaregiver4(weekAvailability, 5);
        console.log(response);
        setkyc(true);
        setload(false);
        localStorage.setItem("screen", "5");
      } catch (error) {
        setload(false);
        // seterror(error.message);
        // setOpen(true);
      }
    }
  };
  return (
    <>
      <Header title="Daily Availability" headerbg="DailyAvailabilitybg" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <h2>Set Availability</h2>
          {/* <Calendar
            // onChange={handledate}
            minDate={new Date()}
            // value={check}
            defaultValue={new Date()}
          /> */}

          <div className="days">
            {Object.keys(weekAvailability).map((day) => (
              <p
                key={day}
                className={
                  selectedDay === day ? "daysname selectedday" : "daysname"
                }
                onClick={() => handleDayClick(day)}
              >
                {day}
              </p>
            ))}
          </div>

          <h2>Select Time For {selectedDay}</h2>
          <div className="Bookcaregiver_timslot">
            <li
              className={
                weekAvailability[selectedDay].fullday === "full"
                  ? "selctedtimeslot"
                  : ""
              }
              onClick={() => handleFullDayChange("full")}
            >
              Full Day
            </li>
            <li
              className={
                weekAvailability[selectedDay].fullday === "half"
                  ? "selctedtimeslot"
                  : ""
              }
              onClick={() => handleFullDayChange("half")}
            >
              Half Day
            </li>
          </div>
          <div className="Bookcaregiver_inputfield_divs">
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">From Time</label>
              <input
                onChange={handleTimeChange}
                value={weekAvailability[selectedDay].start}
                placeholder="time"
                name="start"
                type="time"
                className="Bookcaregiver_input"
              />
            </div>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">To Time</label>
              <input
                onChange={handleTimeChange}
                value={weekAvailability[selectedDay].end}
                //   value={week}
                placeholder="end"
                name="end"
                type="time"
                className="Bookcaregiver_input"
              />
            </div>
          </div>
          {error !== null ? (
            <div className="validation_error" style={{ margin: "2px 0" }}>
              {error}
            </div>
          ) : null}

          <button
            className="Bookcaregiver_button"
            onClick={handlesubmit}
            disabled={load}
          >
            {load ? "Loading....." : "Submit"}
          </button>
        </div>
        <img
          src={caregiverimg}
          alt=""
          className="Bookcaregiverimg"
          loading="lazy"
        />
      </div>
      <KYCPopup
        isOpen={kyc}
        setisOpen={setkyc}
        load={kycload}
        setload={setkycload}
      />
      {kycload && <Loader />}
    </>
  );
};
export default DailyAvailability;
