import { useNavigate } from "react-router-dom";
import caregiverimg from "../assets/aboutleft.png";
import Header from "../Layout/Header";

const About = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header title="About Us" headerbg="aboutbg" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left" style={{ marginTop: "35px" }}>
          <h1>About i-Companion care</h1>
          <p className="about_data">
            At i-Companion, our mission is simple yet profound: to provide
            compassionate and efficient care solutions for individuals in the
            comfort of their homes. We understand the challenges that come with
            seeking care assistance, and we strive to simplify the process while
            making it cost-effective.
          </p>
          <p className="about_data">
            i-Companion was founded with a unique approach to home care. We
            recognized the need for a service that not only meets the practical
            needs of individuals but also prioritizes honesty, integrity, and
            transparency.
          </p>
          <p className="about_data">
            What sets us apart is our dedication to leveling the playing field.
            We believe that everyone deserves access to reliable and dignified
            care, regardless of their background or circumstances. By dropping
            outdated industry standards and embracing innovation, we aim to
            revolutionize the home care experience for both clients and
            companions.
          </p>
          <p className="about_data">
            At i-Companion, we are more than just a care provider; we are your
            trusted companion on the journey to better health and well-being.
            Join us as we embark on this mission to redefine home care, one
            compassionate interaction at a time.
          </p>
        </div>
        <img
          src={caregiverimg}
          alt=""
          className="Bookcaregiverimg"
          style={{ margin: "30px 0" }}
          loading="lazy"
        />
      </div>
    </>
  );
};
export default About;
