import { useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/becameleft1.png";
import "./Becomecare.css";
import { useState } from "react";
import { useFormik } from "formik";
import { becamecaregiver } from "../../service/Mainservice";
import Map from "./Map";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.birthdate) {
    errors.birthdate = "Required";
  }
  if (!values.DL) {
    errors.DL = "Required";
  }
  console.log(values.SSN.length);
  if (!values.SSN) {
    errors.SSN = "Required";
  } else if (values.SSN.length !== 9) {
    errors.SSN = "Must be 9 characters";
  }

  return errors;
};

const Becamecaregiver = () => {
  const [drive, setdrive] = useState(false);
  const [markerPlace, setmarkerPlace] = useState();
  const [placeerror, setplaceerror] = useState();
  const [load, setload] = useState(false);

  const navigate = useNavigate();

  const handlesubmit = async (values, { resetForm }) => {
    if (markerPlace === undefined) {
      setplaceerror("Please select address");
    } else if (placeerror) {
    } else {
      setload(true);

      const { lat, lng } = markerPlace.geometry.location;
      try {
        const data = {
          values,
          isdrive: drive,
          lat: lat,
          long: lng,
          address: markerPlace.formatted_address,
        };
        setload(false);
        const response = await becamecaregiver(data, 2);
        navigate("/BecameaCompanion2");
        localStorage.setItem("screen", "2");
        resetForm();
      } catch (error) {
        setload(false);

        // seterror(error.message);
        // setOpen(true);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      birthdate: "",
      DL: "",
      SSN: "",
      are18: "Yes",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <Header title="Become a Companion" headerbg="becamecaregiverbg" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <h2></h2>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Full Name</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="Bookcaregiver_input"
                  name="name"
                  type="text"
                  placeholder="Full Name"
                />
                {formik.errors.name ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="birthdate">Date of Birth</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.birthdate}
                  className="Bookcaregiver_input"
                  name="birthdate"
                  type="date"
                  placeholder="Date of Birth"
                  max={new Date().toISOString().split("T")[0]}
                />
                {formik.errors.birthdate ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.birthdate}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="address">Address</label>
              <Map
                setplaceerror={setplaceerror}
                setMarkerPlace={setmarkerPlace}
              />

              {placeerror ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {placeerror}
                </div>
              ) : null}
            </div>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="DL">Driving License</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.DL}
                  name="DL"
                  type="text"
                  placeholder="DL"
                  className="Bookcaregiver_input"
                />
                {formik.errors.DL ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.DL}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Can you legally drive in CA?</label>
                <div className="Bookcaregiver_btngrp">
                  <button
                    className={
                      !drive
                        ? "Becamecare_button selected_btn"
                        : "Becamecare_button"
                    }
                    onClick={() => setdrive(false)}
                  >
                    No
                  </button>
                  <button
                    className={
                      drive
                        ? "Becamecare_button selected_btn"
                        : "Becamecare_button"
                    }
                    onClick={() => setdrive(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="are18">Are you 18 and over?</label>
                <select
                  onChange={formik.handleChange}
                  value={formik.values.are18}
                  name="are18"
                  className="Bookcaregiver_input"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="SSN">Social Security Number</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.SSN}
                  name="SSN"
                  type="text"
                  placeholder="SSN"
                  className="Bookcaregiver_input"
                />
                {formik.errors.SSN ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.SSN}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className="Bookcaregiver_inputfield">
              <label htmlFor="source">How did you hear about us?</label>
              <input
                // onChange={handlechange}
                onChange={formik.handleChange}
                value={formik.values.source}
                name="source"
                type="text"
                placeholder="Write here"
                className="Bookcaregiver_input"
              />
              {formik.errors.source ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {formik.errors.source}
                </div>
              ) : null}
            </div> */}

            <button
              className="Bookcaregiver_button"
              type="submit"
              disabled={load}
            >
              {load ? "Loading....." : "Next"}
            </button>
          </form>
        </div>
        <img
          src={caregiverimg}
          alt="caregiver"
          className="Bookcaregiverimg"
          loading="lazy"
        />
      </div>
    </>
  );
};
export default Becamecaregiver;
