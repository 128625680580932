import { ChangeEvent, useState } from "react";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/becameleft2.png";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { becamecaregiver2 } from "../../service/Mainservice";
import Agree from "./Agreement";
import Terms from "../Customer/Terms";
import Privacy from "../Customer/Privacy";

const Becamecaregiver2 = () => {
  const [upload, setupload] = useState(null);
  const [term, setTerm] = useState(false);
  const [privacy, setprivacy] = useState(false);
  const [load, setload] = useState(false);
  const [conset, setconset] = useState(null);
  const [startdate, setstartdate] = useState(new Date());
  const [signdate, setsigndate] = useState(null);
  const [validerror, setvaliderror] = useState(null);
  const [conseterror, setconseterror] = useState(null);
  const [agree, setagree] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    if (conset === null || conset === false) {
      setconseterror("Please consent Employee Agreement");
    }
    if (startdate === null) {
      setvaliderror("Please select Date");
    } else if (upload === null) {
      setvaliderror("signature is Required");
    } else if (signdate === null) {
      setvaliderror("signature Date is Required");
    } else if (conset === null || conset === false) {
      setconseterror("Please consent Employee Agreement");
    } else {
      setvaliderror();
      handlesubmit();
    }
  };
  console.log(signdate === null);
  const handlesubmit = async () => {
    setload(true);
    try {
      const data = {
        isconset: conset,
        startdate: startdate,
        upload: upload,
        signdate: signdate,
      };
      const response = await becamecaregiver2(data, 3);
      setload(false);
      setconset(null);
      setstartdate(null);
      setupload(null);
      setsigndate(null);
      navigate("/BecameaCompanion3");
      localStorage.setItem("screen", "3");
    } catch (error) {
      setload(false);
      console.log(error.message);
    }
  };

  const handlecheck = (e) => {
    const { checked } = e.target;
    if (checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };
  const handlecheck2 = (e) => {
    const { checked } = e.target;
    if (checked) {
      setprivacy(true);
    } else {
      setprivacy(false);
    }
  };

  return (
    <>
      <Header title="Become a Companion" headerbg="becamecaregiverbg2" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <h2>
            Once application process and background check is complete, when can
            you start?{" "}
          </h2>
          <Calendar
            onChange={(e) => setstartdate(e)}
            minDate={new Date()}
            // value={check}
            defaultValue={new Date()}
          />
          <h2>Employement Agreement</h2>
          <p className="becamecare_info">
            Agreement Acknowledge that you have read and understood the terms
            and conditions of this Platform and agree to abide by them. Your
            access to and use of the Platform is conditioned on your acceptance
            of and compliance with these Terms. These Terms apply to all
            visitors, users and others who access or use the Platform. By
            accessing or using the service you agree to be bound by these Terms.
            If you disagree with any part of the terms, then you may not access
            the service. Intellectual Property The Platform and its original
            content, features and functionality are and will remain the
            exclusive property of i-Companion Care LLC and its licensors. Links
            to Other Web Sites Our Service may contain links to third-party web
            sites or services that are not owned or controlled by i-Companion
            Care LLC. i-Companion Care LLC has no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of
            any third-party web sites or services.
          </p>
          <p className="Bookcaregiver_info" onClick={() => setagree(true)}>
            Read More{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_44_388)">
                <path
                  d="M12.1718 11.9998L9.34277 9.17184L10.7568 7.75684L14.9998 11.9998L10.7568 16.2428L9.34277 14.8278L12.1718 11.9998Z"
                  fill="#312920"
                />
              </g>
              <defs>
                <clipPath id="clip0_44_388">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </p>
          <div className="Bookcaregiver_check">
            <input
              type="checkbox"
              name="consent"
              id="consent"
              onClick={(e) => {
                setconset(e.target.checked);
                setconseterror("");
              }}
            />
            <label htmlFor="consent">Consent</label>
          </div>
          {conseterror !== null ? (
            <div className="validation_error" style={{ margin: "2px 0" }}>
              {conseterror}
            </div>
          ) : null}
          <div className="Bookcaregiver_check" onClick={() => setTerm(true)}>
            <input
              type="checkbox"
              name="agree"
              id="agree"
              onChange={handlecheck}
              required
            />
            <label htmlFor="agree">
              {" "}
              I agree to the <span>Terms of Service</span>
            </label>
          </div>
          <div className="Bookcaregiver_check" onClick={() => setprivacy(true)}>
            <input
              type="checkbox"
              name="agree2"
              id="agree2"
              onChange={handlecheck2}
              required
            />
            <label htmlFor="agree">
              {" "}
              I agree to the <span>Privacy Policy</span>
            </label>
          </div>

          <div className="Bookcaregiver_inputfield_divs">
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="sign">Signature</label>
              <div className="Bookcaregiver_inputfield">
                <input
                  type="text"
                  name="sign"
                  id="sign"
                  placeholder="Signature"
                  onChange={(e) => {
                    setupload(e.target.value);
                    setvaliderror("");
                  }}
                  className="Bookcaregiver_input"
                />
              </div>
            </div>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">Date</label>
              <input
                onChange={(e) => {
                  setsigndate(e.target.value);
                  setvaliderror("");
                }}
                value={signdate}
                placeholder="Date"
                name="date"
                type="date"
                max={new Date().toISOString().split("T")[0]}
                className="Bookcaregiver_input"
              />
            </div>
          </div>
          {validerror !== null ? (
            <div className="validation_error" style={{ margin: "2px 0" }}>
              {validerror}
            </div>
          ) : null}

          <button
            className="Bookcaregiver_button"
            onClick={validate}
            disabled={load}
          >
            {load ? "Loading....." : "Next"}
          </button>
        </div>
        <img
          src={caregiverimg}
          alt="caregiving image"
          className="Bookcaregiverimg"
          loading="lazy"
        />
      </div>
      <Agree showTerms={agree} setShowTerms={setagree} />
      <Terms showTerms={term} setShowTerms={setTerm} />
      <Privacy showTerms={privacy} setShowTerms={setprivacy} />
    </>
  );
};
export default Becamecaregiver2;
