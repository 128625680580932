import Header from "../../Layout/Header";
import "./EditProfile.css";
import Profilemenu from "./Profilemenu";
import profile, { editprofile, stripeurl } from "../../service/Mainservice";
import { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useHeaderData } from "../../context/HeaderContext";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import KYCPopup from "./KYC";

const Editprofile = () => {
  const [data, setData] = useState({});
  const [image, setimage] = useState();
  const [error, seterror] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [load, setload] = useState(true);
  const [kyc, setkyc] = useState();
  const [url, seturl] = useState(false);

  const navigate = useNavigate();
  const { updateHeaderData } = useHeaderData();

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const closealert2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getprofile();
    if (localStorage.usertype === "2") {
      checkstripe();
    }
  }, []);

  const checkstripe = async () => {
    const response = await stripeurl();
    console.log(response);
    if (response.data.url) {
      seturl(response.data.url);
      setkyc(true);
    }
  };

  const getprofile = async () => {
    try {
      const response = await profile();
      setData({
        email: response.data.data.email,
        name: response.data.data.full_name,
        contact: response.data.data.phone_number,
        profile_pic: response.data.data.profile_pic,
      });
      setload(false);
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/Login");
      }

      setload(false);
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const Imagechange = (e) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setimage(file);
      // setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      console.log("No file selected");
    }
  };

  const handlesubmit = async () => {
    setload(true);
    try {
      const response = await editprofile(data.name, image);
      updateHeaderData({
        full_name: response.data.data.full_name,
        profile_pic: response.data.data.profile_pic,
      });

      setsuccess(response.data.message);
      setOpen(true);
      setload(false);
    } catch (error) {
      if (error.code === 401) {
        localStorage.clear();
        navigate("/Login");
      }
      seterror(error.message);
      setOpen2(true);
      setload(false);
    }
  };
  return (
    <>
      <Header title="Edit Profile" headerbg="editbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">Edit Profile</h2>

          <div className="editprofileimgdiv">
            <div style={{ position: "relative" }}>
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  className="Becomecareprofile_previewimg"
                  alt="profile"
                  loading="lazy"
                />
              ) : (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + data.profile_pic}
                  alt="profile"
                  loading="lazy"
                />
              )}
              <label htmlFor="preview">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  className="editimg"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="19" cy="19" r="19" fill="#312920" />
                  <g clip-path="url(#clip0_44_1111)">
                    <path
                      d="M15.0778 22.6667L23.1068 14.6376L21.9874 13.5182L13.9583 21.5472V22.6667H15.0778ZM15.734 24.25H12.375V20.8909L21.4277 11.8382C21.5762 11.6898 21.7775 11.6064 21.9874 11.6064C22.1973 11.6064 22.3987 11.6898 22.5471 11.8382L24.7867 14.0779C24.9352 14.2263 25.0185 14.4276 25.0185 14.6376C25.0185 14.8475 24.9352 15.0488 24.7867 15.1973L15.734 24.25ZM12.375 25.8333H26.625V27.4167H12.375V25.8333Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_44_1111">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="translate(10 10)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </label>
              <input
                type="file"
                id="preview"
                accept="image/*"
                onChange={Imagechange}
                name="profile_pic"
              />
            </div>
            <div>
              {" "}
              <div className="editprofile_namediv">
                <div>
                  <p className="editprofile_title">Full Name</p>
                  <input
                    type="text"
                    value={data.name}
                    name="name"
                    onChange={handlechange}
                    className="editprofile_titlevalue"
                  ></input>
                </div>
                <div>
                  <p className="editprofile_title">Mobile</p>
                  <h4 className="editprofile_titlevalue">{data.contact} </h4>
                </div>
              </div>
              <div>
                <p className="editprofile_title">Email</p>
                <h4 className="editprofile_titlevalue">{data?.email} </h4>
              </div>
              <button
                className="Bookcaregiver_button"
                onClick={handlesubmit}
                disabled={load}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={3000}
        onClose={closealert2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      {load && <Loader />}
      <KYCPopup isOpen={kyc} setisOpen={setkyc} url={url} />
    </>
  );
};
export default Editprofile;
