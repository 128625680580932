import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import loginimg from "../../assets/register2.jpg";
import loginimg2 from "../../assets/Register.png";
import { register } from "../../service/Authservice";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Alert, Snackbar, colors } from "@mui/material";
// import { messaging } from "../../Firebase";
// import { getToken } from "firebase/messaging"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Must be at least 6 characters";
  } else if (values.password.length > 20) {
    errors.password = "Must be 20 characters or less";
  }
  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.source) {
    errors.source = "Required";
  }
  return errors;
};

const Register = () => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [success, setsuccess] = useState(false);
  const [load, setload] = useState();

  const unshowref = useRef();
  const location = useLocation();
  const typename = location.state?.type;
  const email = location.state?.email;
  const navigate = useNavigate();
  const phoneinput = parsePhoneNumber(`${value}`);
  const [devicetoken, setdevicetoken] = useState();

  useEffect(() => {
    if (!typename) {
      navigate("/Choosetype");
    }

    // const requestNotificationPermission = async () => {
    //   try {
    //     const permissionResult = await Notification.requestPermission();

    //     if (permissionResult === "granted") {
    //       const currentToken = await getToken(messaging, {
    //         vapidKey:
    //           "BOS2jENcfmydQnDnOwt6HvXqiYV4u8XQ0ay2_eYlLdj8B7THtDnKgYKKnvXR-LXRsEVsYqBOsvuLTnh10AE0rRc",
    //       });
    //       setdevicetoken(currentToken);
    //       // Use the token as needed
    //     }
    //   } catch (error) {
    //     // console.error('Error obtaining device token:', error);
    //   }
    // };

    // requestNotificationPermission();

    //hide login onclick outside of login container
    const handleClickOutside = (event) => {
      if (unshowref.current && !unshowref.current.contains(event.target)) {
        navigate("/");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const closealert2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    if (value === "" || value === undefined) {
    } else {
      setload(true);

      try {
        const response = await register(
          values,
          phoneinput.countryCallingCode,
          phoneinput.nationalNumber,
          typename,
          devicetoken,
          email
        );
        setload(false);

        localStorage.setItem("ntk", response.data.token);
        localStorage.setItem("data", JSON.stringify(response.data.data));
        localStorage.setItem("usertype", response.data.data.user_type);
        localStorage.setItem("screen", response.data.data.screen_no);

        setsuccess(response.data.message);
        setOpen2(true);
        resetForm();
        if (response.data.data.user_type === "1") {
          navigate("/ClientDetails");
        } else {
          navigate("/BecameaCompanion");
        }
      } catch (error) {
        setload(false);
        seterror(error.message);
        setOpen(true);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      name: "",
      source: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });
  console.log(value);
  return (
    <>
      <div className="Auth_BG">
        <div className="Register_main" ref={unshowref}>
          <img
            src={typename === "1" ? loginimg2 : loginimg}
            alt="register"
            className="Auth_img"
            loading="lazy"
            style={{ borderRadius: "10px 0  0 10px" }}
          />
          <div className="Auth_details">
            <h1 className="auth_title">
              Sign Up as {typename === "1" ? "Client" : "Companion"}
            </h1>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="auth_inputfield">
                <PersonOutlineIcon sx={{ color: "#312920" }} />
                <input
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  type="text"
                  placeholder="Full Name"
                />
              </div>
              {formik.errors.name ? (
                <div className="validation_error">{formik.errors.name}</div>
              ) : null}

              <PhoneInput
                placeholder="Phone Number"
                value={value}
                onChange={(e) => setValue(e)}
                defaultCountry="US"
                // countries={countries}
                // limitMaxLength={true}
                className="auth_inputfield"
              />
              {value === "" || value === undefined ? (
                <div className="validation_error">Required</div>
              ) : null}

              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_21_537)">
                    <path
                      d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_21_537">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input value={email} name="email" type="email" readOnly />
                <p
                  className="regchange"
                  onClick={() =>
                    navigate("/SignUpemail", {
                      state: {
                        type: typename,
                      },
                    })
                  }
                >
                  Change
                </p>
              </div>
              {formik.errors.email ? (
                <div className="validation_error">{formik.errors.email}</div>
              ) : null}

              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5_432)">
                    <path
                      d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_432">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  // onChange={handlechange}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              {formik.errors.password ? (
                <div className="validation_error">{formik.errors.password}</div>
              ) : null}
              <div className="auth_inputfield">
                <input
                  onChange={formik.handleChange}
                  value={formik.values.source}
                  name="source"
                  type="text"
                  placeholder="How did you here about Us"
                />
              </div>
              {formik.errors.source ? (
                <div className="validation_error">{formik.errors.source}</div>
              ) : null}

              <button
                type="submit"
                className="auth_submitbutton"
                disabled={load}
              >
                {load ? "Loading..." : "Register "}
              </button>
              <p className="auth_notaccount">
                Already have an account?{" "}
                <button
                  className="auth_secoundbutton"
                  onClick={() => navigate("/Login")}
                >
                  Sign In.{" "}
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={3000}
        onClose={closealert2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Register;
