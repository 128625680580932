import { useNavigate } from "react-router-dom";
import serviceleft from "../assets/servicecontact.jpg";
import Header from "../Layout/Header";

const Service = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header title="Our Services" headerbg="servicebg" />
      <h1 className="servicedes">
        We aim to provide high-quality care services within the comfort of your
        own environment, utilizing a technology-driven approach that connects
        companions with those in need of care.
      </h1>
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <li className="about_data">Activities of daily living</li>
          <li className="about_data">Assist with day-to-day struggles</li>
          <li className="about_data">Range of motion</li>
          <li className="about_data">Sitter service</li>
          <li className="about_data">Family relief</li>
          <li className="about_data"> Cognitive engagement </li>
          <li className="about_data">Companionship</li>
          <li className="about_data">Hospice Support</li>
          <li className="about_data">Social activities</li>
          <li className="about_data">Language/cultural accommodations </li>
          <li className="about_data">Exercising</li>
          <h2>Personal care</h2>
          <li className="about_data">Personal hygiene</li>
          <li className="about_data">Mobility assistance</li>
          <li className="about_data">Feeding</li>
          <li className="about_data">Light housekeeping </li>
          <li className="about_data">Medication reminder </li>
          <li className="about_data">Incontinence care</li>
        </div>
      </div>
      <div className="servicediv">
        <div className="servicecontactinfo">
          <h1 className="servicediv_title">
            INTERESTED IN CARE OR WANT TO PROVIDE CARE
          </h1>
          <div className="services_buttonlist">
            <a href="tel:(408) 579-9929" className="services_button">
              Call Us (408) 579-9929
            </a>{" "}
            <a href="mailto:info@i-companion.com" className="services_button">
              Email Us
            </a>{" "}
          </div>
        </div>
        <img src={serviceleft} alt="service" loading="lazy" />
      </div>
    </>
  );
};
export default Service;
