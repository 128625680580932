import Header from "../../Layout/Header";
import "./Refer.css";
import Profilemenu from "./Profilemenu";
import referimg from "../../assets/referfriend.png";
import { refer } from "../../service/Mainservice";
import { useFormik } from "formik";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import Loader from "../../Loader/Loader";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.contact) {
    errors.contact = "Required";
  }
  return errors;
};

const Referfriend = () => {
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState();
  const [load, setload] = useState(false);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);

    try {
      const response = await refer(values);
      setsuccess(response.data.message);
      setOpen(true);
      setload(false);
      resetForm();
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <Header title="Refer a Friend" headerbg="referbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="refer_info">
          {" "}
          <div className="refer_left">
            <h2 className="editprofile_maintitle">Refer a Friend</h2>

            <form
              className="referinfodiv"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              {" "}
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Client Name</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="Bookcaregiver_input"
                  name="name"
                  type="text"
                  placeholder="Client Name"
                />
                {formik.errors.name ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="contact">Mobile Number</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.contact}
                  className="Bookcaregiver_input"
                  name="contact"
                  type="text"
                  placeholder="Mobile Number"
                />
                {formik.errors.contact ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.contact}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="email">Email Address</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="Bookcaregiver_input"
                  name="email"
                  type="text"
                  placeholder="Email"
                />
                {formik.errors.email ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <button
                className="Bookcaregiver_button"
                type="submit"
                disabled={load}
              >
                {load ? "loading..." : "Submit"}
              </button>
            </form>
          </div>
          <img
            src={referimg}
            alt="refer"
            className="refer_img"
            loading="lazy"
          />
        </div>
      </div>
      {load && <Loader />}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Referfriend;
